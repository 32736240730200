/* eslint-disable no-use-before-define */
import React, { useEffect } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  // Chip,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
  Typography,
  makeStyles
} from '@material-ui/core';
// import ReceiptIcon from '@material-ui/icons/ReceiptOutlined';
import {
  BarChart as BarChartIcon,
  User as UserIcon,
  PieChart as PieChartIcon,
} from 'react-feather';
import FastfoodIcon from '@material-ui/icons/Fastfood';
import ScheduleIcon from '@material-ui/icons/Schedule';
import LocalMallIcon from '@material-ui/icons/LocalMall';
import StorefrontIcon from '@material-ui/icons/Storefront';
import HelpIcon from '@material-ui/icons/Help';
import LogoDark from 'src/components/LogoDark';
import useAuth from 'src/hooks/useAuth';
import NavItem from './NavItem';
import { URL } from 'src/constants';

const sections = [
  {
    subheader: 'Overview',
    items: [
      {
        title: 'Dashboard',
        icon: PieChartIcon,
        href: '/app'
      },
      // {
      //   title: 'Dashboard Alternative',
      //   icon: BarChartIcon,
      //   href: '/app/reports/dashboard-alternative'
      // }
    ]
  },
  {
    subheader: 'Management',
    items: [
      {
        title: 'Meals',
        icon: FastfoodIcon,
        href: '/app/meals'
      },
      {
        title: 'Schedule',
        icon: ScheduleIcon,
        href: '/app/schedule',
        items: [
          {
            title: 'Opening Schedule',
            href: '/app/schedule'
          },
          {
            title: 'Collection Schedule',
            href: '/app/schedule/collection'
          },
        ]
      },
      {
        title: 'Orders',
        icon: LocalMallIcon,
        href: '/app/orders',
        items: [
          {
            title: 'List Orders',
            href: '/app/orders'
          },
        ]
      },
      // {
      //   title: 'Invoices',
      //   icon: ReceiptIcon,
      //   href: '/app/management/invoices',
      //   items: [
      //     {
      //       title: 'List Invoices',
      //       href: '/app/management/invoices'
      //     },
      //   ]
      // }
    ]
  },
  {
    subheader: 'Insights',
    items: [
      {
        title: 'Reports',
        href: '/app/extra/charts',
        icon: BarChartIcon,
      },
    ]
  },
  {
    subheader: 'Settings',
    items: [
      {
        title: 'Store Profile',
        href: '/app/store',
        icon: StorefrontIcon
      },
      {
        title: 'Account',
        href: '/app/settings',
        icon: UserIcon
      },
    ]
  },
  {
    subheader: 'Support',
    items: [
      {
        title: 'Help center',
        href: '/app/help',
        icon: HelpIcon,
      },
    ]
  },
];

function renderNavItems({
  items,
  pathname,
  depth = 0
}) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({
  acc,
  pathname,
  item,
  depth
}) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256,
    backgroundColor: theme.palette.primary.main,
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)',
    // backgroundColor: '#6d2929',
    backgroundColor: theme.palette.primary.main,
    // '& *':{
    //   color: '#fff',
    // }
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  },
  divider:{
    backgroundColor: '#f8f7f8',
  },
  subheader:{
    color: '#fff'
  }
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const { user } = useAuth();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box
            p={2}
            display="flex"
            justifyContent="center"
          >
            <RouterLink to="/">
              <LogoDark className={classes.dar} />
            </RouterLink>
          </Box>
        </Hidden>
        <Box p={2}>
          <Box
            display="flex"
            justifyContent="center"
          >
            <RouterLink to="/app/account">
              <Avatar
                alt="User"
                className={classes.avatar}
                src={user.avatar? URL+'/storage/profile/'+user.avatar: null}
              />
            </RouterLink>
          </Box>
          <Box
            mt={2}
            textAlign="center"
          >
            <Link
              component={RouterLink}
              to="/app/account"
              variant="h5"
              style={{ color: "#fff" }}
              underline="none"
            >
              {user.fname}
            </Link>
            {/* <Typography
              variant="body2"
              color="textSecondary"
            >
              Your tier:
              {' '}
              <Link
                component={RouterLink}
                to="/pricing"
              >
                {user.tier}
              </Link>
            </Typography> */}
          </Box>
        </Box>
        <Divider className={classes.divider} />
        <Box p={2}>
          {sections.map((section) => (
            <List
              key={section.subheader}
              subheader={(
                <ListSubheader
                  className={classes.subheader}
                  disableGutters
                  disableSticky
                >
                  {section.subheader}
                </ListSubheader>
              )}
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname
              })}
            </List>
          ))}
        </Box>
        <Divider className={classes.divider} />
        <Box p={2}>
          <Box
            p={2}
            borderRadius="borderRadius"
            bgcolor="background.light"
          >
            <Typography
              variant="h6"
              style={{ color: "#fff" }}
            >
              Need Help?
            </Typography>
            <Link
              variant="subtitle1"
              style={{ color: "#fff" }}
              component={RouterLink}
              to="/docs"
            >
              Check our docs
            </Link>
          </Box>
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
