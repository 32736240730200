import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { BackendURL } from 'src/constants';

const initialState = {
  notifications: [],
  unSeen: 0
};

const slice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    getNotifications(state, action) {
      const { notifications, unSeen } = action.payload;

      state.notifications = notifications;
      state.unSeen = unSeen;
    },
    clearNotifications(state) {
      state.unSeen = 0;
    }
  }
});

export const reducer = slice.reducer;

export const getNotifications = () => async (dispatch) => {
  const response = await axios.get(BackendURL+'/notifications/get');
  dispatch(slice.actions.getNotifications(response.data));
};

export const clearNotifications = () => async (dispatch) => {
  dispatch(slice.actions.clearNotifications());
};

export default slice;
