import React from 'react';

const LogoDark = (props) => {
  return (
    <img
      alt="LogoDark"
      src="/static/dark-logo.png"
      {...props}
    />
  );
}

export default LogoDark;
