import React from 'react';
import { 
  Grid,
  Box,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import Form from './Form';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%'
  },
  mainGrid:{
    [theme.breakpoints.up('md')]:{
      minHeight: '100vh',
    },
  },
  bg:{
    backgroundImage: 'url("./static/images/pexels-flo-dahm-541216.jpg")',
    backgroundSize: 'cover',
    [theme.breakpoints.up('md')]:{
      minHeight: '100%',
    },
    [theme.breakpoints.down('md')]:{
      minHeight: '300px',
    }
  },
  badge:{
    maxWidth: '150px'
  }
}));

const HomeView = () => {
  const classes = useStyles();

  return (
    <Page
      className={classes.root}
      title="Home page"
    > 
      <Grid container className={classes.mainGrid}>
        <Grid item md={6} xs={12}>
          <Box className={classes.bg}>
          </Box>
        </Grid>
        <Grid item md={6} xs={12}>
          <Grid container>
            <Grid item md={2} xs={12} />
            <Grid item md={8} xs={12}>
              <Box pb={5} px={3}>
                <Form />
              </Box>
            </Grid>
            <Grid item md={3} xs={12} />
              <Grid item xs={12}>
                <Grid container>
                  <Grid item md={4} xs={12} />
                  <Grid item>
                    <img alt="badge" className={classes.badge} src="./static/images/app-store-badge.png" />
                  </Grid>
                  <Grid item>
                    <img alt="badge" className={classes.badge} src="./static/images/google-play-badge.png" />
                  </Grid>
                </Grid>
              </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Page>
  );
};

export default HomeView;
