import React, {
  useRef,
  useState,
  useCallback,
  useEffect
} from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Avatar,
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover,
  SvgIcon,
  Tooltip,
  Typography,
  Badge,
  makeStyles
} from '@material-ui/core';
import {
  Bell as BellIcon,
  Package as PackageIcon,
  MessageCircle as MessageIcon,
  Truck as TruckIcon
} from 'react-feather';
import { useDispatch, useSelector } from 'src/store';
import { getNotifications, clearNotifications } from 'src/slices/notification';
import axios from 'axios';
import { BackendURL, getDate } from 'src/constants';
import { useHistory } from 'react-router-dom';
import Pusher from 'pusher-js';
import { useSnackbar } from 'notistack';


const iconsMap = [
  MessageIcon,
  PackageIcon,
  TruckIcon
];

const useStyles = makeStyles((theme) => ({
  popover: {
    width: 320
  },
  icon: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText
  }
}));

const Notifications = () => {
  const classes = useStyles();
  const history = useHistory();
  const { notifications, unSeen } = useSelector((state) => state.notifications);
  const ref = useRef(null);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setOpen] = useState(false);

  const readAll = async () => {
    dispatch(clearNotifications());
    await axios.get(BackendURL+'/notifications/read/all');
  };

  const handleOpen = async () => {
    setOpen(true);
    readAll();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const listenNotifications = useCallback((store_id) => {
      // Pusher.logToConsole = true;
    const pusher = new Pusher('de2070e48f8b114e74b5', {
      cluster: 'eu',
      encrypted: true
    });
    const channel = pusher.subscribe('notify-channel');
    channel.bind(`NotifyStore-${store_id}`, data => {
      dispatch(getNotifications());
      enqueueSnackbar(data.message, { variant: 'success' });
    });
  }, [dispatch, enqueueSnackbar]);

  const CheckProfile = useCallback(async () => {
    try{
      let res = await axios.get(BackendURL+'/user');
      if(!res.data.profile){
        history.push('/business/signup');
      }else{
        listenNotifications(res.data.profile.id);
        dispatch(getNotifications());
        if(res.data.profile.collection_schedule.length === 0 || res.data.profile.schedule.length === 0){
          history.push('/business/signup');
        }
      }
    }catch(err){
      console.log(err);
    }
  }, [history, dispatch, listenNotifications]);

  const getNotificationLink = (notification) => {
    if(notification.type === 1){
      return '/app/orders/'+notification.link_id;
    }
    return false;
  }

  useEffect(() => {
    CheckProfile();
  }, [dispatch, CheckProfile]);

  return (
    <>
      <Tooltip title="Notifications">
        <IconButton
          color="inherit"
          ref={ref}
          onClick={handleOpen}
        >
          <Badge badgeContent={unSeen} color="secondary">
            <SvgIcon>
              <BellIcon />
            </SvgIcon>
          </Badge>
        </IconButton>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        classes={{ paper: classes.popover }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <Box p={2}>
          <Typography
            variant="h5"
            color="textPrimary"
          >
            Notifications
          </Typography>
        </Box>
        {notifications.length === 0 ? (
          <Box p={2}>
            <Typography
              variant="h6"
              color="textPrimary"
            >
              There are no notifications
            </Typography>
          </Box>
        ) : (
          <>
            <List disablePadding>
              {notifications.map((notification) => {
                const Icon = iconsMap[notification.type];

                return (
                  <ListItem
                    component={RouterLink}
                    divider
                    key={notification.id}
                    style={notification.seen === 0? { backgroundColor: '#f1f1f1' } :null} 
                    to={getNotificationLink(notification)}
                  >
                    <ListItemAvatar>
                      <Avatar
                        className={classes.icon}
                      >
                        <SvgIcon fontSize="small">
                          <Icon />
                        </SvgIcon>
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={notification.message}
                      primaryTypographyProps={{ variant: 'subtitle2', color: 'textPrimary' }}
                      secondary={getDate(notification.created_at)}
                    />
                  </ListItem>
                );
              })}
            </List>
            <Box
              p={1}
              display="flex"
              justifyContent="center"
            >
              <Button
                size="small"
                onClick={readAll}
              >
                Mark all as read
              </Button>
            </Box>
          </>
        )}
      </Popover>
    </>
  );
};

export default Notifications;
