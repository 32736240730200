export const APP_VERSION = '3.1.0';

export const ENABLE_REDUX_DEV_TOOLS = true;

// export const BackendURL = 'http://127.0.0.1:8000/api';
// export const URL = 'http://127.0.0.1:8000';

export const BackendURL = 'https://backend.maistorinkiniai.lt/api';
export const URL = 'https://backend.maistorinkiniai.lt';

export const THEMES = {
  LIGHT: 'LIGHT',
  ONE_DARK: 'ONE_DARK',
  UNICORN: 'UNICORN'
};

const getReadableDate = (lastLogin) => {
  var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  var d = new Date(lastLogin);
  var date = d.getDate();
  var month = months[d.getMonth()];
  var year = d.getFullYear();
  return date + " " + month + " " + year;
}
const getReadableDay = (lastLogin) => {
  var d = new Date(lastLogin);
  var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  var day = days[d.getDay()];
  return day;
}

const getReadableTime = (lastLogin) => {
  var d = new Date(lastLogin);
  var hr = d.getHours();
  var min = d.getMinutes();
  if (min < 10) {
      min = "0" + min;
  }
  var ampm = "am";
  if(hr >= 12){
      ampm = "pm";
  }
  if(hr > 12){
      hr = hr - 12;
  }
  if(hr === 0){
      hr = 12;
  }
  return hr + ":" + min + ampm;
}
const convertUTCDateToLocalDate = (date) => {
  var newDate = new Date(date.getTime()+date.getTimezoneOffset()*60*1000);
  var offset = date.getTimezoneOffset() / 60;
  var hours = date.getHours();
  if(offset < 0){
      newDate.setHours(hours);
  }else{
      newDate.setHours(hours - offset);
  }
  return newDate;   
}

export const getDate = (d) => { 
  if(!d){ return '--'; }
  let date = convertUTCDateToLocalDate(new Date(d));
  return ( getReadableDay(date) + ' ' +  getReadableDate(date) + ' at ' + getReadableTime(date) );
};

export const getOrderStatus = (orderStatus) => {
  const map = [
      'Pending',
      'Accepted',
      'In process',
      'Completed',
      'Canceled',
      'Rejected',
      'Ready to pickup',
  ];

  const text = map[orderStatus];

  return text;
};

export const isPast = (date) => {
  let rDate = new Date(date).getTime();
  let now = new Date().getTime();
  if(now > rDate){
    return true;
  }else {
    return false;
  }
}

export const GoogleMapsAPIKey = "AIzaSyDDelk-SlCSwZgYwEcmc7n4KJvp5TPCHMQ";