import React, { useState } from 'react';
import clsx from 'clsx';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { useHistory } from 'react-router-dom';
// import { Link as RouterLink, useHistory } from 'react-router-dom';
import {
  Box,
  Button,
  // Link,
  FormHelperText,
  TextField,
  Typography,
  Grid,
  makeStyles
} from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import { BackendURL } from '../../../constants';
import axios from 'axios';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
// import VerifyForm from './VerifyForm';
import { useSnackbar } from 'notistack';
import useAuth from 'src/hooks/useAuth';
import Autocomplete from '@material-ui/lab/Autocomplete';
let countries = require('./Countries.json');
const useStyles = makeStyles(() => ({
  root: {
    
  },
}));

const Form = ({ className, ...rest }) => {
  const classes = useStyles();
  const { login } = useAuth();
  // const [ token, seToken ] = useState();
  const [ isDone, setDone ] = useState(false);
  const { enqueueSnackbar }  = useSnackbar();
  const history = useHistory();
  const isMountedRef = useIsMountedRef();

  function countryToFlag(isoCode) {
    return typeof String.fromCodePoint !== 'undefined'
      ? isoCode
          .toUpperCase()
          .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
      : isoCode;
  }
  
  if(isDone){
    // return <VerifyForm token={token} />;
  }

  return (
    <Formik
      initialValues={{
        email: '',
        fname: '',
        lname: '',
        number: '',
        prefix: '',
        country_code: '',
        password: '',
        passwordConfirm: '',
        submit: null
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
        fname: Yup.string().max(255).required('Your First name is required'),
        lname: Yup.string().max(255).required('Your Last name is required'),
        number: Yup.number().required('Phone is required'),
        password: Yup.string().min(8, 'Password should be 8 chars minimum.')
          .max(255)
          .matches(/(?=.*[a-z])/, 'Password should contain one lowercase character')
          .matches(/(?=.*[A-Z])/, 'Password should contain one uppercase character')
          .matches(/(?=.*[0-9])/, 'Password should contain one number')
          .matches(/(?=.*[!@#$%^&*])/, 'Password should contain symbol, or whitespace character')
          .required('Password is required'),
          passwordConfirm: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match')
      })}
      onSubmit={async (values, {
        setErrors,
        setStatus,
        setSubmitting
      }) => {
        try {
          setSubmitting(true);
          // await register(values.name, values.lastName, values.email);
            let config22 = {
              headers: {
                'Access-Control-Allow-Origin': '*',
                'Accept': 'application/json',
                'Content-Type': 'application/json',
              }
            }
            values.phone = parseInt(values.number);
            const res = await axios.post(BackendURL+'/signup', values, config22);
            // console.log(res.data.success);
          if (isMountedRef.current) {
            if(res.status === 201){
              setStatus({ success: true });
              setSubmitting(false);
              if(res.data){
                // seToken(res.data.token);
                setDone(true);
                await login(values.email, values.password);
                if(!res.data.user.profiles){
                  history.push('/business/signup');
                }else{
                  history.push('/app');
                }
              }
            }
          }
        } catch (err) {
          console.error(err);
          setStatus({ success: false });
          let errors = [];
          if(typeof err.response.data === "object"){
            if(err.response.data.line === 88){
              errors.push("Invalid phone number");
            }
            for (const [key] of Object.entries(err.response.data)) {
              err.response.data[key].map((error) => {
                return errors.push(error);
              })
            }
          }else{
            errors.push("Error cannot handle your request");
          }
          setErrors({ submit: errors });
          setSubmitting(false);
          enqueueSnackbar('Error cannot handle your request', { variant: "error" });
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        touched,
        values
      }) => (
        <form noValidate className={clsx(classes.root, className)} onSubmit={handleSubmit} {...rest}>
                      <Box style={{textAlign: 'right'}} mt={5} mb={7}>
                {/* <Typography varient="body1" color="textPrimary">
                  Have an account?
                  <Link
                    component={RouterLink}
                    to="/login"
                    variant="body1"
                    color="primary"
                  > Log in</Link> 
                </Typography>*/}
              </Box>
              <Box style={{textAlign: 'center'}} mb={5}>
                <Typography color="textPrimary" gutterBottom variant="h1" mb={3}>
                  Business Sign up
                </Typography>
                <Typography color="textSecondary" gutterBottom variant="body1" mb={3}>
                  Increase your earnings and help the world to reduce food waste
                </Typography>
                {/*
                <Typography color="textPrimary" variant="body1" mb={3}>
                  Turn loans around faster by streamlining the process
                </Typography> */}
              </Box>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <TextField
                error={Boolean(touched.fname && errors.fname)}
                fullWidth
                helperText={touched.fname && errors.fname}
                label="First Name *"
                name="fname"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.fname}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                error={Boolean(touched.lname && errors.lname)}
                fullWidth
                helperText={touched.lname && errors.lname}
                label="Last Name *"
                name="lname"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.lname}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Autocomplete
                id="country-select-demo"
                options={countries}
                classes={{
                  option: classes.option,
                }}
                onChange={ (e, val) => { 
                  if(val){
                      if(val.label){
                          setFieldValue('prefix', val.phone); 
                          setFieldValue('country_code', val.code); 
                          return false;
                      }
                  }
                } }    
                getOptionLabel={(option) => option.label + ' ' + option.phone}
                renderOption={(option) => (
                  <React.Fragment>
                    <span style={{ paddingRight: '5px' }}>{countryToFlag(option.code)}</span>
                    {option.label} {option.phone}
                  </React.Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Choose a country"
                    variant="outlined"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                error={Boolean(touched.number && errors.number)}
                fullWidth
                helperText={touched.number && errors.number}
                label="Phone number *"
                name="number"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.number}
                variant="outlined"
                InputProps={{
                  startAdornment: <InputAdornment position="start">{values.prefix}</InputAdornment>,
                }}
                type="number"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={Boolean(touched.email && errors.email)}
                fullWidth
                helperText={touched.email && errors.email}
                label="Email Address*"
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                type="email"
                value={values.email}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                error={Boolean(touched.password && errors.password)}
                fullWidth
                helperText={touched.password && errors.password}
                label="Enter a password"
                name="password"
                onBlur={handleBlur}
                onChange={handleChange}
                type="password"
                value={values.password}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                error={Boolean(touched.passwordConfirm && errors.passwordConfirm)}
                fullWidth
                helperText={touched.passwordConfirm && errors.passwordConfirm}
                label="Password Confirmation"
                name="passwordConfirm"
                onBlur={handleBlur}
                onChange={handleChange}
                type="password"
                value={values.passwordConfirm}
                variant="outlined"
              />
            </Grid>
          </Grid>
          {Boolean(touched.policy && errors.policy) && (
            <FormHelperText error>
              {errors.policy}
            </FormHelperText>
          )}
          {errors.submit && (
            <Box mt={3}>
              {
                errors.submit.map((error) => {
                  return (
                    <FormHelperText key={error} error>
                      {error}
                    </FormHelperText>
                  )
                })
              }
            </Box>
          )}
          <Box mt={2}>
            <Button color="secondary" 
              disabled={isSubmitting}
              fullWidth size="large" type="submit" variant="contained" style={{textTransform: 'capitalize'}}>
              Next
            </Button>
          </Box>
          {/* <Box mt={5} mb={4}>
            <Typography align="center" color="textPrimary" varient="body1">
              By creating a Jupiter account, you're agreeing to accept the <RouterLink style={{textDecoration: 'none'}} to="/privacy-policy" variant="body1" color="primary">Jupiter Customer Terms of Service.</RouterLink>
            </Typography>
          </Box>
          <Box mb={3}>
            <Divider style={{maxWidth: '300px', margin: '0 auto'}} />
          </Box>
          <Box mb={10}>
            <Typography varient="body1" color="textPrimary" align="center">
              We're committed to your privacy. Jupiter uses the information you provide to us to contact you about our relevant content, products, and services. You may unsubscribe from these communications at any time. For more information, check out our Privacy policy.
            </Typography>
          </Box> */}
        </form>
      )}
    </Formik>
  );
};

Form.propTypes = {
  className: PropTypes.string
};

export default Form;